import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./auth/authConfig";

function handleLogin(instance: any) {
    instance.loginRedirect(loginRequest).catch((e: any) => {
        console.error(e);
    });
}

export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <a href={void (0)} style={{ cursor: 'pointer' }} onClick={() => handleLogin(instance)}>Sign in</a>
    );
}