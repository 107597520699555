export enum DateTimeFormatStrings {
    FORMAT_1 = 'dd-MM-yyyy'
}

export enum ServiceCallStatus {
    idle = 'idle',
    loading = 'loading',
    saving = 'saving',
    failed = 'failed',
    rejected = 'rejected'
}
