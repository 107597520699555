import { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Container, Form } from 'react-bootstrap';
import 'react-data-grid/lib/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Utilities } from '../../shared/utils/utilities';
import { DateTimeFormatStrings, ServiceCallStatus } from '../../shared/constants';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { createAsync, getDetailAsync, initCurrentData, updateAsync } from './bannerTextSlice';
import { BannerText } from '../../models/bannerText';
import { RootState } from '../../app/store';
import LoadingOverlay from '../../components/loading-overlay';
import ErrorMessage from '../../components/error-message';
import SalesOrgs from '../../shared/SalesOrgs.json';

export function BannerTextEdit() {
  const { salesorg, createat } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const status = useAppSelector((state: RootState) => state.bannerText.status);
  const errorMsg = useAppSelector((state: RootState) => state.bannerText.errorMsg);
  const currentBannerText = useAppSelector((state: RootState) => state.bannerText.currentData);

  const [isAdd] = useState(salesorg === undefined || salesorg === null);
  const [selectedSalesOrg, setSelectedSalesOrg] = useState(SalesOrgs[0].value);
  const [bannerTextData, setBannerTextData] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [deleteAfterExpireChecked, setdeleteAfterExpireCheck] = useState(true);

  useEffect(() => {
    if (isAdd) {
      dispatch(initCurrentData());
      return;
    }

    dispatch(getDetailAsync({ salesOrg: salesorg!, createdAt: Number(createat) }));
  }, [isAdd, salesorg, createat, dispatch]);

  useEffect(() => {
    if (isAdd || currentBannerText === null || currentBannerText === undefined) {
      return;
    }

    setSelectedSalesOrg(currentBannerText.salesOrg);
    setBannerTextData(currentBannerText.bannerTextData);
    setStartDate(new Date(currentBannerText.startDate));
    setEndDate(new Date(currentBannerText.endDate));
    setdeleteAfterExpireCheck(currentBannerText.expireAt !== null);
  }, [isAdd, currentBannerText]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    var bannerText = {
      salesOrg: selectedSalesOrg,
      bannerTextData: bannerTextData,
      startDate: Utilities.formatDate(startDate, DateTimeFormatStrings.FORMAT_1),
      endDate: Utilities.formatDate(endDate, DateTimeFormatStrings.FORMAT_1),
      expireAt: deleteAfterExpireChecked ? endDate.getTime() : null
    } as BannerText;

    if (!isAdd) {
      bannerText.createdAt = Number(createat);
    }

    dispatch(isAdd ? createAsync(bannerText) : updateAsync(bannerText))
      .then((data) => {
        if (data.meta.requestStatus !== ServiceCallStatus.rejected) {
          navigate('/bannertext')
        };
      });
  }

  return (
    <Container fluid className="mt-3 layout-container">
      <h1>Banner Text</h1>
      {
        status === ServiceCallStatus.failed ? <ErrorMessage>{errorMsg}</ErrorMessage> : null
      }
      {
        status === ServiceCallStatus.loading ?
          <LoadingOverlay /> :
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="ControlSaleOrg">
              <Form.Label className='fw-bold'>Sales Organization</Form.Label>
              <Form.Select disabled={!isAdd} value={selectedSalesOrg} onChange={(event) => { setSelectedSalesOrg(event.target.value) }}>
                {
                  SalesOrgs.map(item => <option key={item.name} value={item.value}>{item.name}</option>)
                }
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="ControlText">
              <Form.Label className='fw-bold'>Banner Text</Form.Label>
              <Form.Control value={bannerTextData} onChange={(event) => setBannerTextData(event.target.value)} as="textarea" rows={3} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="ControlStartDate">
              <Form.Label className='fw-bold'>Start Date (DD-MM-YYYY)</Form.Label>
              <DatePicker dateFormat={DateTimeFormatStrings.FORMAT_1} selected={startDate} showMonthDropdown showYearDropdown dropdownMode="select" onChange={(date: Date) => setStartDate(date)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="ControlEndDate">
              <Form.Label className='fw-bold'>End Date (DD-MM-YYYY)</Form.Label>
              <DatePicker dateFormat={DateTimeFormatStrings.FORMAT_1} selected={endDate} showMonthDropdown showYearDropdown dropdownMode="select" onChange={(date: Date) => setEndDate(date)} />

            </Form.Group>
            <Form.Group className="mb-3" controlId="ControlExpire">
              <Form.Check className='fw-bold' type='checkbox' checked={deleteAfterExpireChecked} onChange={e => setdeleteAfterExpireCheck(e.currentTarget.checked)} label='Delete banner text after expired' />
            </Form.Group>

            <div className="mt-2">
              <Button size="lg" variant="secondary" onClick={() => navigate('/bannertext')}>Cancel</Button>
              <Button disabled={status === ServiceCallStatus.saving} size="lg" className="ap-green float-end" type="submit">
                {
                  status === ServiceCallStatus.saving ? "Save..." : "Save"
                }
              </Button>
            </div>
          </Form>
      }

    </Container >
  );
}
