import axios from 'axios';
import { Agent } from '../../models/user-agent';

export async function getAgent(email: string) {
  const response = await axios.get<Agent>(`/agent/${email}`);
  return response.data;
}

export async function saveAgent(companyKey: string, email: string) {
  const response = await axios.post<Agent>('/agent', { companyKey: companyKey, email: email });
  return response.data;
}

export async function removeAgent(companyKey: string, email: string) {
  const response = await axios.delete<Agent>('/agent', { data: { companyKey: companyKey, email: email } });
  return response.data;
}