import { DateTimeFormatStrings } from "../constants";

export class Utilities {
  static padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0');
  }

  static formatDate = (date: Date, format: string) => {
    switch (format) {
      case DateTimeFormatStrings.FORMAT_1:
        return [
          this.padTo2Digits(date.getDate()),
          this.padTo2Digits(date.getMonth() + 1),
          date.getFullYear()
        ].join('-');

      default:
        return date.toLocaleDateString();
    }
  }
}