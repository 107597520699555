import { BrowserCacheLocation } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_AAD_CLIENT_ID as string,  //Application ID in Azure App Registration
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AAD_TENANT_ID}`,  //Tenant ID
      redirectUri: process.env.REACT_APP_AAD_REDIRECT_URL  as string,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false,
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: [`api://${process.env.REACT_APP_AAD_CLIENT_ID}/access_as_user`]
  };

  export const graphRequest = {
    scopes: ["https://graph.microsoft.com/.default"]
   };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };

  export enum AppRoles {
    Visitors = "Visitors",
    Administrator = "Administrator"
  };
  