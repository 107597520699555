import React from 'react';
import './App.css';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  return (
    <>
      <Row className="justify-content-center mt-5">
        <Col xs="6" lg="4">
          <Button size="lg" className="w-100 ap-green" onClick={() => navigate('/bcp')}>
            BCP Recovery Message
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center mt-3">
        <Col xs="6" lg="4">
          <Button size="lg" className="w-100 ap-green" onClick={() => navigate('/bannertext')}>
            Search Text Banner
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center mt-3">
        <Col xs="6" lg="4">
          <Button size="lg" className="w-100 ap-green" onClick={() => navigate('/agent')}>
            Agent Link
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default App;
