import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Bcp } from '../../models/bcp';
import { addBcp, deleteBcp, getAllBcps, getBcp, updateBcp } from './bcpAPI';
import { ServiceCallStatus } from '../../shared/constants';

export interface BcpState {
  data: Bcp[];
  currentData: Bcp | null;
  status: ServiceCallStatus;
  errorMsg: string;
}

const initialState: BcpState = {
  data: [] as Bcp[],
  currentData: null,
  status: ServiceCallStatus.idle,
  errorMsg: ""
};

export const getAllAsync = createAsyncThunk(
  'bcp/getAll',
  async () => {
    const response = await getAllBcps();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getDetailAsync = createAsyncThunk(
  'bcp/get',
  async ({ salesOrg, createdAt }: { salesOrg: string, createdAt: number }) => {
    const response = await getBcp(salesOrg, createdAt);
    return response;
  }
);

export const createAsync = createAsyncThunk(
  'bcp/create',
  async (bcp: Bcp) => {
    const response = await addBcp(bcp);
    return response;
  }
);

export const updateAsync = createAsyncThunk(
  'bcp/update',
  async (bcp: Bcp) => {
    const response = await updateBcp(bcp);
    return response;
  }
);

export const deleteAsync = createAsyncThunk(
  'bcp/delete',
  async (bcp: Bcp) => {
    const response = await deleteBcp(bcp);
    return response;
  }
);

export const bcpSlice = createSlice({
  name: 'bcp',
  initialState,
  reducers: {
    initCurrentData: (state) => {
      state.currentData = null;
      state.status = ServiceCallStatus.idle;
      state.errorMsg = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAsync.pending, (state) => {
        state.status = ServiceCallStatus.loading;
      })
      .addCase(getAllAsync.fulfilled, (state, action) => {
        state.status = ServiceCallStatus.idle;
        if (action.payload) {
          action.payload.forEach(item => {
            item.modifiedDate = new Date(item.modifiedDate).toLocaleString();
          })
        }
        state.data = action.payload;
      })
      .addCase(getAllAsync.rejected, (state) => {
        state.status = ServiceCallStatus.failed;
        state.errorMsg = "BCP Message list load failed.";
      })
      .addCase(getDetailAsync.pending, (state) => {
        state.status = ServiceCallStatus.loading;
      })
      .addCase(getDetailAsync.fulfilled, (state, action) => {
        state.status = ServiceCallStatus.idle;
        state.currentData = action.payload;
      })
      .addCase(getDetailAsync.rejected, (state) => {
        state.status = ServiceCallStatus.failed;
        state.errorMsg = "BCP Message detail load failed.";
      })
      .addCase(createAsync.pending, (state) => {
        state.status = ServiceCallStatus.saving;
      })
      .addCase(createAsync.fulfilled, (state) => {
        state.status = ServiceCallStatus.idle;
      })
      .addCase(createAsync.rejected, (state) => {
        state.status = ServiceCallStatus.failed;
        state.errorMsg = "BCP Message create failed.";
      })
      .addCase(updateAsync.pending, (state) => {
        state.status = ServiceCallStatus.saving;
      })
      .addCase(updateAsync.fulfilled, (state) => {
        state.status = ServiceCallStatus.idle;
      })
      .addCase(updateAsync.rejected, (state) => {
        state.status = ServiceCallStatus.failed;
        state.errorMsg = "BCP Message save failed.";
      })
  },
});

export const { initCurrentData } = bcpSlice.actions;

export default bcpSlice.reducer;
