import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BannerText } from '../../models/bannerText';
import { addBannerText, deleteBannerText, getAllBannerTexts, getBannerText, updateBannerText } from './bannerTextAPI';
import { ServiceCallStatus } from '../../shared/constants';

export interface BannerTextState {
  data: BannerText[];
  currentData: BannerText | null;
  status: ServiceCallStatus;
  errorMsg: string;
}

const initialState: BannerTextState = {
  data: [] as BannerText[],
  currentData: null,
  status: ServiceCallStatus.idle,
  errorMsg: ""
};

export const getAllAsync = createAsyncThunk(
  'bannerText/getAll',
  async () => {
    const response = await getAllBannerTexts();
    return response;
  }
);

export const getDetailAsync = createAsyncThunk(
  'bannerText/get',
  async ({ salesOrg, createdAt }: { salesOrg: string, createdAt: number }) => {
    const response = await getBannerText(salesOrg, createdAt);
    return response;
  }
);

export const createAsync = createAsyncThunk(
  'bannerText/create',
  async (bannerText: BannerText) => {
    const response = await addBannerText(bannerText);
    return response;
  }
);

export const updateAsync = createAsyncThunk(
  'bannerText/update',
  async (bannerText: BannerText) => {
    const response = await updateBannerText(bannerText);
    return response;
  }
);

export const deleteAsync = createAsyncThunk(
  'bannerText/delete',
  async (bannerText: BannerText) => {
    const response = await deleteBannerText(bannerText);
    return response;
  }
);

export const bannerTextSlice = createSlice({
  name: 'bannerText',
  initialState,
  reducers: {
    initCurrentData: (state) => {
      state.currentData = null;
      state.status = ServiceCallStatus.idle;
      state.errorMsg = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAsync.pending, (state) => {
        state.status = ServiceCallStatus.loading;
      })
      .addCase(getAllAsync.fulfilled, (state, action) => {
        state.status = ServiceCallStatus.idle;
        if (action.payload) {
          action.payload.forEach(item => {
            item.modifiedDate = new Date(item.modifiedDate).toLocaleString();
          })
        }
        state.data = action.payload;
      })
      .addCase(getAllAsync.rejected, (state) => {
        state.status = ServiceCallStatus.failed;
        state.errorMsg = "Banner Text list load failed.";
      })
      .addCase(getDetailAsync.pending, (state) => {
        state.status = ServiceCallStatus.loading;
      })
      .addCase(getDetailAsync.fulfilled, (state, action) => {
        state.status = ServiceCallStatus.idle;
        state.currentData = action.payload;
      })
      .addCase(getDetailAsync.rejected, (state) => {
        state.status = ServiceCallStatus.failed;
        state.errorMsg = "Banner Text detail load failed.";
      })
      .addCase(createAsync.pending, (state) => {
        state.status = ServiceCallStatus.saving;
      })
      .addCase(createAsync.fulfilled, (state) => {
        state.status = ServiceCallStatus.idle;
      })
      .addCase(createAsync.rejected, (state) => {
        state.status = ServiceCallStatus.failed;
        state.errorMsg = "Banner Text create failed.";
      })
      .addCase(updateAsync.pending, (state) => {
        state.status = ServiceCallStatus.saving;
      })
      .addCase(updateAsync.fulfilled, (state) => {
        state.status = ServiceCallStatus.idle;
      })
      .addCase(updateAsync.rejected, (state) => {
        state.status = ServiceCallStatus.failed;
        state.errorMsg = "Banner Text save failed.";
      })
  },
});

export const { initCurrentData } = bannerTextSlice.actions;

export default bannerTextSlice.reducer;
