import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router';
import { RootState } from '../../app/store';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { getUserAgent, saveUserAgent, toggleMode, removeUserAgent } from './agentLinkSlice';
import { ServiceCallStatus } from '../../shared/constants';
import ErrorMessage from '../../components/error-message';

export function AgentLink() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const agent = useAppSelector((state: RootState) => state.agent.data);
  const errorMsg = useAppSelector((state: RootState) => state.agent.errorMsg);
  const mode = useAppSelector((state: RootState) => state.agent.mode);
  const searchStatus = useAppSelector((state: RootState) => state.agent.searchStatus);
  const angentChangeStatus = useAppSelector((state: RootState) => state.agent.angentChangeStatus);
  const [companyKey, setCompanyKey] = useState('');
  const [email, setEmail] = useState("");

  useEffect(() => {
    dispatch(getUserAgent(email));
    setCompanyKey('');
  }, []);

  const onSearchUser = () => {
    dispatch(getUserAgent(email));
  }

  const onSaveAgent = () => {
    dispatch(saveUserAgent({ agentNumber: companyKey, email: agent.email }))
      .finally(() => dispatch(getUserAgent(agent.email)));
  }

  const onRemoveAgent = () => {
    if (window.confirm(`Are you sure to remove the agent link?`)) {
      dispatch(removeUserAgent({ agentNumber: agent.companyKey, email }));
    }
  }

  return (
    <Container fluid className="mt-3 layout-container">
      <h1>Agent Link</h1>
      {
        searchStatus === ServiceCallStatus.failed || angentChangeStatus === ServiceCallStatus.failed ? <ErrorMessage>{errorMsg}</ErrorMessage> : null
      }
      <Row className='mt-2'>
        <Col md="6">
          <Form.Control value={email} onChange={(event) => setEmail(event.target.value)} placeholder="Enter email to search the user..." />
        </Col>
        <Col>
          <Button disabled={searchStatus === ServiceCallStatus.loading} className="ap-green me-2" onClick={onSearchUser}>
            {
              searchStatus === ServiceCallStatus.loading ? 'Processing...' : 'Search'
            }
          </Button>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <b>Current Email: </b>{agent?.email}
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <b>Current Agent: </b>{agent?.companyKey}
        </Col>
      </Row>

      {
        searchStatus !== ServiceCallStatus.loading ?
          <React.Fragment>
            <Row className='mt-2'>
              <Col md="6">
                {mode === 'edit' ? <Form.Control placeholder="Enter agent number..." onChange={(e: any) => setCompanyKey(e.target.value)} value={companyKey} /> : null}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                {mode === 'view' ?
                  <React.Fragment>
                    <Button size="lg" className="ap-green me-2" onClick={() => dispatch(toggleMode())}>Change Agent</Button>
                    <Button size="lg" hidden className="ap-green me-2" onClick={onRemoveAgent}>Remove Agent</Button>
                    <Button size="lg" variant="secondary" onClick={() => navigate('/')}>Back</Button>
                  </React.Fragment> :
                  <React.Fragment>
                    <Button disabled={angentChangeStatus === ServiceCallStatus.loading} size="lg" className="ap-green me-2"
                      onClick={onSaveAgent}>
                      {
                        angentChangeStatus === ServiceCallStatus.loading ? 'Processing...' : 'Save'
                      }
                    </Button>
                    {
                      angentChangeStatus === ServiceCallStatus.loading ? null :
                        <Button size="lg" variant="secondary" onClick={() => dispatch(toggleMode())}>Cancel</Button>
                    }
                  </React.Fragment>}
              </Col>
            </Row>
          </React.Fragment>
          : null
      }
    </Container>
  );
}
