import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import bcpReducer from '../features/bcp/bcpSlice';
import bannerTextReducer from '../features/bannerText/bannerTextSlice';
import agentReducer from '../features/agentLink/agentLinkSlice';

export const store = configureStore({
  reducer: {
    bcp: bcpReducer,
    bannerText: bannerTextReducer,
    agent: agentReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
