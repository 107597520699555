import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Agent } from '../../models/user-agent';
import { saveAgent, getAgent, removeAgent } from './agentLinkAPI';
import { ServiceCallStatus } from '../../shared/constants';

export interface UserAgentState {
  data: Agent;
  mode: 'view' | 'edit',
  searchStatus: ServiceCallStatus;
  angentChangeStatus: ServiceCallStatus;
  errorMsg: string;
}

const initialState: UserAgentState = {
  data: {} as Agent,
  mode: 'view',
  searchStatus: ServiceCallStatus.idle,
  angentChangeStatus: ServiceCallStatus.idle,
  errorMsg: ''
};

export const getUserAgent = createAsyncThunk(
  'agent/get',
  async (email: string) => {
    const response = await getAgent(email);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const saveUserAgent = createAsyncThunk(
  'agent/save',
  async ({ agentNumber, email }: { agentNumber: string, email: string }, thunkAPI) => {
    const response = await saveAgent(agentNumber, email);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const removeUserAgent = createAsyncThunk(
  'agent/remove',
  async ({ agentNumber, email }: { agentNumber: string, email: string }, thunkAPI) => {
    const response = await removeAgent(agentNumber, email);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    toggleMode: (state) => {
      state.mode = (state.mode === 'view' ? 'edit' : 'view');
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getUserAgent.pending, (state) => {
        state.searchStatus = ServiceCallStatus.loading;
      })
      .addCase(getUserAgent.fulfilled, (state, action) => {
        state.searchStatus = ServiceCallStatus.idle;
        state.data = action.payload;
      })
      .addCase(getUserAgent.rejected, (state, action) => {
        state.searchStatus = ServiceCallStatus.failed;
        state.errorMsg = action.error.message ?? '';
      })
      .addCase(saveUserAgent.pending, (state) => {
        state.angentChangeStatus = ServiceCallStatus.loading;
      })
      .addCase(saveUserAgent.fulfilled, (state) => {
        state.angentChangeStatus = ServiceCallStatus.idle;
        state.mode = 'view';
      })
      .addCase(saveUserAgent.rejected, (state, action) => {
        state.angentChangeStatus = ServiceCallStatus.failed;
        state.errorMsg = action.error.message ?? '';
      })
      .addCase(removeUserAgent.pending, (state) => {
        state.angentChangeStatus = ServiceCallStatus.loading;
      })
      .addCase(removeUserAgent.fulfilled, (state) => {
        state.angentChangeStatus = ServiceCallStatus.idle;
      })
      .addCase(removeUserAgent.rejected, (state, action) => {
        state.angentChangeStatus = ServiceCallStatus.failed;
        state.errorMsg = action.error.message ?? '';
      });
  },
});

export const { toggleMode } = agentSlice.actions;

export default agentSlice.reducer;
