import { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Container, Form } from 'react-bootstrap';
import 'react-data-grid/lib/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Utilities } from '../../shared/utils/utilities';
import { DateTimeFormatStrings, ServiceCallStatus } from '../../shared/constants';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { createAsync, getDetailAsync, initCurrentData, updateAsync } from './bcpSlice';
import { Bcp } from '../../models/bcp';
import { RootState } from '../../app/store';
import LoadingOverlay from '../../components/loading-overlay';
import ErrorMessage from '../../components/error-message';
import SalesOrgs from '../../shared/SalesOrgs.json';

export function BcpEdit() {
  const { salesorg, createat } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const status = useAppSelector((state: RootState) => state.bcp.status);
  const errorMsg = useAppSelector((state: RootState) => state.bcp.errorMsg);
  const currentBcp = useAppSelector((state: RootState) => state.bcp.currentData);

  const [isAdd] = useState(salesorg === undefined || salesorg === null);
  const [selectedSalesOrg, setSelectedSalesOrg] = useState(SalesOrgs[0].value);
  const [messageText, setMessageText] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [deleteAfterExpireChecked, setdeleteAfterExpireCheck] = useState(true);

  useEffect(() => {
    if (isAdd) {
      dispatch(initCurrentData());
      return;
    }

    dispatch(getDetailAsync({ salesOrg: salesorg!, createdAt: Number(createat) }));
  }, [isAdd, salesorg, createat, dispatch]);

  useEffect(() => {
    if (isAdd || currentBcp === null || currentBcp === undefined) {
      return;
    }

    setSelectedSalesOrg(currentBcp.salesOrg);
    setMessageText(currentBcp.messageText);
    setStartDate(new Date(currentBcp.startDate));
    setEndDate(new Date(currentBcp.endDate));
    setdeleteAfterExpireCheck(currentBcp.expireAt !== null);
  }, [isAdd, currentBcp]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    var bcp = {
      salesOrg: selectedSalesOrg,
      messageText: messageText,
      startDate: Utilities.formatDate(startDate, DateTimeFormatStrings.FORMAT_1),
      endDate: Utilities.formatDate(endDate, DateTimeFormatStrings.FORMAT_1),
      expireAt: deleteAfterExpireChecked ? endDate.getTime() : null
    } as Bcp;

    if (!isAdd) {
      bcp.createdAt = Number(createat);
    }

    dispatch(isAdd ? createAsync(bcp) : updateAsync(bcp))
      .then((data) => {
        if (data.meta.requestStatus !== ServiceCallStatus.rejected) {
          navigate('/bcp')
        };
      });
  }

  return (
    <Container fluid className="mt-3 layout-container">
      <h1>BCP Message</h1>
      {
        status === ServiceCallStatus.failed ? <ErrorMessage>{errorMsg}</ErrorMessage> : null
      }
      {
        status === ServiceCallStatus.loading ?
          <LoadingOverlay /> :
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="ControlSaleOrg">
              <Form.Label className='fw-bold'>Sales Organization</Form.Label>
              <Form.Select disabled={!isAdd} value={selectedSalesOrg} onChange={(event) => { setSelectedSalesOrg(event.target.value) }}>
                {
                  SalesOrgs.map(item => <option key={item.name} value={item.value}>{item.name}</option>)
                }
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="ControlMessage">
              <Form.Label className='fw-bold'>Message Text</Form.Label>
              <Form.Control value={messageText} onChange={(event) => setMessageText(event.target.value)} as="textarea" rows={3} />
            </Form.Group>
            <Form.Group className="mb-3 fw-bold" controlId="ControlStartDate">
              <Form.Label>Start Date (DD-MM-YYYY)</Form.Label>
              <DatePicker dateFormat={DateTimeFormatStrings.FORMAT_1} selected={startDate} showMonthDropdown showYearDropdown dropdownMode="select" onChange={(date: Date) => setStartDate(date)} />
            </Form.Group>
            <Form.Group className="mb-3 fw-bold" controlId="ControlEndDate">
              <Form.Label>End Date (DD-MM-YYYY)</Form.Label>
              <DatePicker dateFormat={DateTimeFormatStrings.FORMAT_1} selected={endDate} showMonthDropdown showYearDropdown dropdownMode="select" onChange={(date: Date) => setEndDate(date)} />

            </Form.Group>
            <Form.Group className="mb-3" controlId="ControlExpire">
              <Form.Check type='checkbox' className='fw-bold' checked={deleteAfterExpireChecked} onChange={e => setdeleteAfterExpireCheck(e.currentTarget.checked)} label='Delete BCP Message after expired' />
            </Form.Group>

            <div className="mt-2">
              <Button size="lg" variant="secondary" onClick={() => navigate('/bcp')}>Cancel</Button>
              <Button disabled={status === ServiceCallStatus.saving} size="lg" className="ap-green float-end" type="submit">
                {
                  status === ServiceCallStatus.saving ? "Save..." : "Save"
                }
              </Button>
            </div>
          </Form>
      }

    </Container>
  );
}
