import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { ProfileContent } from "./ProfileContent";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { AppRoles } from "./auth/authConfig";
import { Link } from "react-router-dom";

export const PageLayout = ({ children }: { children: React.ReactNode }) => {
    useMsalAuthentication(InteractionType.Redirect);
    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const [hasAccess, setHasAccess] = useState(true);

    useEffect(() => {
        if (account) {
            const roles = (account.idTokenClaims as any).roles ? (account.idTokenClaims as any).roles as string[] : [];
            setHasAccess(roles.some((i: any) => i === AppRoles.Visitors.toString()));
        }
    }, [account]);

    const content = <>
        <Container fluid className="ap-top-bar ap-green">
            <div className="layout-container">
                <Row>
                    <Col className="d-none d-sm-done d-md-block">
                        <AuthenticatedTemplate>
                            <Container fluid>
                                <Row>
                                    <Col md={6} className="ps-0">
                                        <ProfileContent />
                                    </Col>
                                    <Col md={6} className="text-end pe-0">
                                        <Link to="/" className="me-3">Home Page</Link>
                                        <SignOutButton />
                                    </Col>
                                </Row>
                            </Container>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <SignInButton />
                        </UnauthenticatedTemplate>
                    </Col>
                </Row>
            </div>
        </Container>
        {children}
    </>;

    return (hasAccess ? content : <><Navigate to="/no-access"></Navigate></>);
};