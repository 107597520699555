import * as React from 'react';
import { Spinner } from 'react-bootstrap';
import './style.css';

export default function LoadingOverlay() {
    return <React.Fragment>
        <div className='spinner-container'>
            <Spinner animation="border" variant="secondary" />
        </div>
    </React.Fragment>
}