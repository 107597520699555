import axios from 'axios';
import { BannerText } from '../../models/bannerText';

export async function getAllBannerTexts() {
  const response = await axios.get<BannerText[]>('/bannertext/list');
  return response.data;
}

export async function getBannerText(salesOrg: string, createdAt: number) {
  const response = await axios.get<BannerText>(`/bannertext/${salesOrg}/${createdAt}`);
  return response.data;
}

export async function addBannerText(bannerText: BannerText) {
  const response = await axios.post<BannerText[]>(`/bannertext`, bannerText);
  return response.data;
}

export async function updateBannerText(bannerText: BannerText) {
  const response = await axios.put<BannerText[]>(`/bannertext`, bannerText);
  return response.data;
}

export async function deleteBannerText(bannerText: BannerText) {
  const response = await axios.delete<BannerText>(`/bannertext/${bannerText.salesOrg}/${bannerText.createdAt}`);
  return response.data;
}