import axios from "axios";
import { MsalProvider } from "@azure/msal-react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NoAccess from './NoAccess';
import { BcpList } from './features/bcp/Bcp';
import { BcpEdit } from './features/bcp/BcpEdit';
import { PageLayout } from './PageLayout';
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "./auth/authConfig";
import { AgentLink } from './features/agentLink/agentLink';
import { BannerTextList } from "./features/bannerText/BannerText";
import { BannerTextEdit } from "./features/bannerText/BannerTextEdit";

const container = document.getElementById('root')!;
const root = createRoot(container);

const msalInstance = new PublicClientApplication(msalConfig);

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API_URL;
axios.interceptors.request.use(
  async (response) => {
    const account = msalInstance.getAllAccounts()[0];
    const msalResponse = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    response.headers = { 'Accept': 'application/json', 'Authorization': `Bearer ${msalResponse.accessToken}` };
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);

root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <BrowserRouter>
        <PageLayout>
          <Routes>
            <Route path='/agent' element={<AgentLink />} />
            <Route path='/bcp' element={<BcpList />} />
            <Route path={'/bcp/create'} element={<BcpEdit />} />
            <Route path={'/bcp/edit/:salesorg/:createat'} element={<BcpEdit />} />
            <Route path='/bannertext' element={<BannerTextList />} />
            <Route path={'/bannertext/create'} element={<BannerTextEdit />} />
            <Route path={'/bannertext/edit/:salesorg/:createat'} element={<BannerTextEdit />} />
            <Route path='/no-access' element={<NoAccess />} />
            <Route path="/" element={<App />} />
            <Route path="*" element={<App />} />
          </Routes>
        </PageLayout>
      </BrowserRouter>
    </Provider>
  </MsalProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
