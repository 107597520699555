import axios from 'axios';
import { Bcp } from '../../models/bcp';

export async function getAllBcps() {
  const response = await axios.get<Bcp[]>('/bcp/list');
  return response.data;
}

export async function getBcp(salesOrg: string, createdAt: number) {
  const response = await axios.get<Bcp>(`/bcp/${salesOrg}/${createdAt}`);
  return response.data;
}

export async function addBcp(bcp: Bcp) {
  const response = await axios.post<Bcp[]>(`/bcp`, bcp);
  return response.data;
}

export async function updateBcp(bcp: Bcp) {
  const response = await axios.put<Bcp[]>(`/bcp`, bcp);
  return response.data;
}

export async function deleteBcp(bcp: Bcp) {
  const response = await axios.delete<Bcp>(`/bcp/${bcp.salesOrg}/${bcp.createdAt}`);
  return response.data;
}