import { useEffect, useMemo } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DataGrid from 'react-data-grid';
import 'react-data-grid/lib/styles.css';
import { RootState } from '../../app/store';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { deleteAsync, getAllAsync } from './bcpSlice';
import LoadingOverlay from '../../components/loading-overlay';
import { ServiceCallStatus } from '../../shared/constants';
import { linkColumn } from '../../components/link-column';
import { Bcp } from '../../models/bcp';
import ErrorMessage from '../../components/error-message';

export function BcpList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const bcpList = useAppSelector((state: RootState) => state.bcp.data);
  const status = useAppSelector((state: RootState) => state.bcp.status);
  const errorMsg = useAppSelector((state: RootState) => state.bcp.errorMsg);

  useEffect(() => {
    dispatch(getAllAsync());
  }, []);

  const isLoading = useMemo(() => status === ServiceCallStatus.loading, [status]);

  const dataGridColumns = useMemo(() => {
    return [
      { key: 'edit', name: 'Edit', width: 60, renderCell: (porps: any) => linkColumn(porps, 'Edit', onEditClick) },
      { key: 'delete', name: 'Delete', width: 75, renderCell: (porps: any) => linkColumn(porps, 'Delete', onDeleteClick) },
      { key: 'salesOrg', name: 'Sales Org', width: 90 },
      { key: 'startDate', name: 'Start Date', width: 100 },
      { key: 'endDate', name: 'End Date', width: 100 },
      { key: 'messageText', name: 'Message Text' },
      { key: 'modifiedBy', name: 'Last Updated By', width: 150 },
      { key: 'modifiedDate', name: 'Last Updated Time', width: 180 },
    ];
  }, []);

  const onEditClick = (row: Bcp) => {
    navigate(`/bcp/edit/${row.salesOrg}/${row.createdAt}`)
  }

  const onDeleteClick = (row: Bcp) => {
    if (window.confirm(`Are you sure to delete this item?`)) {
      dispatch(deleteAsync(row)).finally(() => dispatch(getAllAsync()));
    }
  }

  return (
    <Container fluid className="mt-3 layout-container">
      <h1>BCP message</h1>
      {
        status === ServiceCallStatus.failed ? <ErrorMessage>{errorMsg}</ErrorMessage> : null
      }
      {
        isLoading ?
          <LoadingOverlay /> :
          <DataGrid columns={dataGridColumns} rows={bcpList} />
      }

      <div className="mt-2">
        <Button size="lg" variant="secondary" onClick={() => navigate('/')}>Back</Button>
        <Button disabled={isLoading} size="lg" className="ap-green float-end" onClick={() => navigate('/bcp/create')}>New</Button>
      </div>
    </Container>
  );
}
