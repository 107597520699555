import React from "react";
import { useMsal } from "@azure/msal-react";


export const SignOutButton = () => {
    const { instance } = useMsal();

    function handleLogout(instance: any) {
        instance.logoutRedirect().catch((e: any) => {
            console.error(e);
        });
    }

    return (
        <a href={void (0)} style={{ cursor: 'pointer' }} onClick={() => handleLogout(instance)}>Sign out</a>
    );
}